import React, {useState} from "react";
import {useAuth} from "../util/auth";
import sessionInfo from "../util/sessionInfo";
import {Story, story} from "../utils/story";
import {observer} from "mobx-react-lite";
import {Box, Button, Card, CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import PayMe from "./PayMe";
import {SignupPopup} from "./SignupPopup";
import ShareButton from "./ShareButton";
import track from "../util/frontend_analytics";

export function UserInputCard({waitingUser}: { waitingUser: boolean }) {
    const [text, setText] = useState('')
    const [totalTyped, setTotalTyped] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [paymeActive, setPaymeActive] = useState(false)
    const [signupRequireActive, setSignupRequired] = useState(false)
    const auth = useAuth();
    const submitButtonRef = React.useRef<HTMLButtonElement>(null);

    async function generateSection() {

        // if the user still hasn't signed up, require sign up after one generated section
        const usedCredits = sessionInfo.usedCredits;
        const notSignedUp = ((!auth.user) && (usedCredits >= 2))
        if (notSignedUp) {
            track("story_signup_required", {theme: story.theme})

            setSignupRequired(true)
            return

        }
        if (auth.user && (auth.user.availableCredits <= 0)) {

            setPaymeActive(true)
            track("story_credit_exhausted", {theme: story.theme})
            return
        }
        sessionInfo.useCredit()
        setText('')
        setProcessing(true)
        story.setProcessing(true)

        const prevSectionId = story.sections[story.sections.length - 1].id
        try {
            var retry = false
            await story.generateNewSection(text, prevSectionId, retry);
        } catch (e) {
            console.log(e)
            await new Promise(r => setTimeout(r, 12000));
            console.log('retrying...')
            try {
                retry = true
                await story.generateNewSection(text, prevSectionId, retry);
            } catch (e) {
                console.log(e)
                console.log('retrying one last time!...')
                try {
                    retry = true
                    await new Promise(r => setTimeout(r, 5000));
                    await story.generateNewSection(text, prevSectionId, retry);
                } catch (e) {
                    console.log(e)
                    console.log('failed to generate section')
                    alert('failed to generate section, please try again')
                }
            }
        }
        setProcessing(false)
        story.setProcessing(false)
    }


    const StoryViewerButton = observer(({story}: { story: Story }) => {
        return (
            <Button
                ref={submitButtonRef} // Set the ref to the Button


                variant={'contained'}
                color={'primary'}
                endIcon={processing &&
                    <Grid item>
                        <CircularProgress size={'20px'}/>
                    </Grid>}
                disabled={processing || story.underEdit}
                onClick={async () => {

                    setTimeout(() => submitButtonRef.current?.scrollIntoView({behavior: 'smooth'}), 300);


                    await generateSection();

                }}>
                Submit
            </Button>
        )
    })

    return waitingUser ?
        (<Grid container spacing={1}>
                <Grid item sm={12}>
                    <Card style={{margin: "10px", padding: "10px"}} elevation={10}>
                        <Box mb={2}>
                            <Typography variant={'h6'}> What happens next? </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                               multiline
                                               onChange={(e) => {
                                                   setText(e.target.value);
                                                   // make the background color a shade darker with every keystroke
                                                   // select "body" element and set background color
                                                   // @ts-ignore
                                                   setTotalTyped(totalTyped + 1)
                                                   var delta = Math.round(totalTyped / 10)
                                                   const maxDelta = 180
                                                   delta = Math.min(delta, maxDelta)
                                                   const color = `rgb(${Math.max(0, 255 - delta)}, ${Math.max(0, 255 - delta)}, ${Math.max(0, 255 - delta)})`;
                                                   const bodyElement = document.querySelector('body');
                                                   if (bodyElement) {
                                                       bodyElement.style.backgroundColor = color;
                                                   }
                                               }
                                               }
                                               value={text}
                                               placeholder={'Explain where the story should go in a brief sentence'}
                                    >
                                    </TextField>

                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container
                                          alignItems="center"
                                          justifyContent={'center'}
                                          spacing={2}>
                                        <Grid item xs={3}>
                                            {paymeActive && <PayMe open={paymeActive}
                                                                   dismissCallback={() => {
                                                                       setPaymeActive(false)
                                                                   }}
                                                                   runOut={true}


                                            />}
                                            {signupRequireActive &&
                                                <SignupPopup setSignupModal={setSignupRequired}
                                                             signupModal={signupRequireActive}/>}
                                        </Grid>

                                        <Grid container
                                              alignItems="center"
                                              justifyContent={'center'}
                                              spacing={2}>
                                            <Grid item>
                                                <StoryViewerButton story={story}/>

                                            </Grid>
                                            <Grid item>
                                                <ShareButton link={`www.mytales.io/story/${story.id}`}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        ) : null
}